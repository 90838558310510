import { CommonModule } from "@angular/common";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withInMemoryScrolling } from "@angular/router";
import { CookieModule } from "ngx-cookie";
import { ToastrModule } from "ngx-toastr";
import { NgwWowModule } from "ngx-wow";
import { SharedModule } from "primeng/api";
import { Approutes } from "./app.routes";
import { Globals } from "./globals";
import { AuthGuardFrontUsers } from "./shared/services/auth-guard/auth-guard-frontusers.service";
import { AuthGuardLogin } from "./shared/services/auth-guard/auth-guard-login.service";
import { AuthService } from "./shared/services/auth.service";
import { HttpInterceptor } from "./shared/services/httpinterceptor";
import { LoginService } from "./shared/services/login.service";
import { UserService } from "./shared/services/user.service";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      NgwWowModule,
      SharedModule,
      CookieModule.withOptions(),
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: "toast-top-right",
        closeButton: true,
        tapToDismiss: true,
      })
    ),
    Globals,
    AuthService,
    LoginService,
    AuthGuardFrontUsers,
    AuthGuardLogin,
    UserService,
    HttpInterceptor,
    provideRouter(
      Approutes,
      withInMemoryScrolling({ scrollPositionRestoration: "top" })
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
