import { Routes } from "@angular/router";
import { PrintComponent } from "./layout/main/print/print.component";
import { AuthGuardLogin } from "./shared/services/auth-guard/auth-guard-login.service";

export const Approutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../app/layout/auth/auth.routes").then((m) => m.authRoutes),
  },

  {
    path: "",
    loadChildren: () =>
      import("../app/layout/main/main.routes").then((m) => m.mainRoutes),
    canActivate: [AuthGuardLogin],
  },

  {
    path: "print/booking/:id",
    component: PrintComponent,
  },
  {
    path: "health_map/files/:petId/:id",
    component: PrintComponent,
  },
  {
    path: "print/healthmap-report",
    component: PrintComponent,
  },
  {
    path: "print/healthmap-report/:id",
    component: PrintComponent,
  },
  {
    path: "**",
    redirectTo: "/",
  },
];
