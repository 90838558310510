export const environment = {
  production: true,
};
export const API_PREFIX = "https://api.safarivet.com/api/";
export const IMG_PREFIX = "https://api.safarivet.com";

/* export const API_PREFIX = "http://192.168.1.108:5000/api/";
  export const IMG_PREFIX = "http://192.168.1.108:5000"; */

export const COOKIE_PREFIX = "wsfr";
export const COOKIE_ADMIN_PREFIX = "wsap";
// export const FRONT_DOMAIN = "https://wellness.demoserver.biz";
export const ADMIN_DOMAIN = "https://wellness.safarivet.com/admin";
