import { FormGroup } from "@angular/forms";

import * as moment from "moment-timezone";
import * as moment_tz from "moment-timezone";
import { PAGINATION, TIME_ZONE } from "../config/app.constants";
declare var $: any;

export class Utils {
  private static scrollPosition = 0;

  static getDateFormat(dateObject) {
    const dateFormat = [];
    dateFormat[0] = dateObject.getDate();
    dateFormat[1] = dateObject.getMonth() + 1;
    dateFormat[2] = dateObject.getFullYear();
    return dateFormat;
  }

  static htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>|&nbsp;/gm, "") : "";
  }

  // Custom validator 'mustMatch' for Password and Confirm password
  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * some default setting throw out the projcts
   */
  static getJQValidationOption() {
    return {
      errorElement: "span",
      errorPlacement: function (error, element) {
        if ($(element).parent().hasClass("label-moves-up")) {
          $(element).parent().parent().append(error);
        }
      },
    };
  }

  /**
   * Datatable configuration default settings
   */
  static getDataTableSettings(uid = "first") {
    return {
      pagingType: "full_numbers_no_ellipses",
      pageLength: PAGINATION,
      serverSide: true,
      processing: true,
      autoWidth: false,
      dom: 'lft<"custom-paginate pagin-' + uid + ' clearfix"ip>',
      order: [],
      searching: false,
      lengthChange: false,
      paginationLocation: "outer-pagination-block",
      language: {
        info: "Showing _START_ to _END_ of _TOTAL_ Records",
        searchPlaceholder: " Type to Search",
        paginate: {
          previous: "Prev",
          first: "First",
          last: "Last",
          next: "Next",
        },
      },
      initComplete: function (settings, json) {
        if ($(".outer-pagination-block-" + uid).length > 0) {
          $(".outer-pagination-block-" + uid)
            .append($(".pagin-" + uid))
            .addClass("dataTables_wrapper");
        }
      },
    };
  }

  /**
   * calculate current data and time with given date and time and returns seconds/minutes/hours/days/months/years
   */
  static getDateDifference(
    value: Date | moment.Moment,
    unit?: moment.unitOfTime.Diff
  ): string {
    const created = moment_tz.tz(value, TIME_ZONE);
    const today = moment();
    let diff;
    if (today.diff(created, "years", false)) {
      diff = today.diff(created, "years", false);
      return diff > 1 ? diff + " years ago" : diff + " year ago";
    } else if (today.diff(created, "months", false)) {
      diff = today.diff(created, "months", false);
      return diff > 1 ? diff + " months ago" : diff + " month ago";
    } else if (today.diff(created, "days", false)) {
      diff = today.diff(created, "days", false);
      return diff > 1 ? diff + " days ago" : diff + " day ago";
    } else if (today.diff(created, "hours", false)) {
      diff = today.diff(created, "hours", false);
      return diff > 1 ? diff + " hours ago" : diff + " hour ago";
    } else if (today.diff(created, "minutes", false)) {
      diff = today.diff(created, "minutes", false);
      return diff > 1 ? diff + " mins ago" : diff + " min ago";
    } else {
      return today.diff(created, "hours", false) + " seconds ago";
    }
  }

  /**
   * Get device type information among (Desktop/Mobile/Tablet)
   */
  static getDeviceType(): string {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  }
  async getLatLongFromAddress(address: string): Promise<void> {
    const results = await this.getLatLong(address);
    if (results?.length) {
      const location = results[0].geometry?.location;

      const latlong = {
        lat: location.lat(),
        lng: location.lng(),
      };

      window.open(
        `https://www.google.com/maps?q=${latlong?.lat},${latlong?.lng}`,
        "_blank"
      );
    }
  }

  getLatLong(address: string): Promise<google.maps.GeocoderResult[]> {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }
  static disableBodyScroll() {
    this.scrollPosition = window.scrollY;
    document.body.style.overflow = "hidden";
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  static enableBodyScroll() {
    document.body.style.overflow = "";
    document.body.style.position = "";
    document.body.style.top = "";
    // window.scrollTo(0, this.scrollPosition);
    window.scrollTo(0, 0);
  }
}
