import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { ToastrService } from "ngx-toastr";
import {
  Observable,
  throwError as observableThrowError,
  throwError,
} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { COOKIE_PREFIX } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpinterceptorService {
  constructor(
    private http: HttpClient,
    private router: Router,
    public cookieService: CookieService,
    private toasterService: ToastrService
  ) {}

  public post(url, data, flash_flag): Observable<any> {
    // .set('out_post_id', localStorage.getItem('selectedOutpostId'))
    var token = this.cookieService.get(`${COOKIE_PREFIX}_token`)
      ? this.cookieService.get(`${COOKIE_PREFIX}_token`)
      : localStorage.getItem(`${COOKIE_PREFIX}_token`);
    return this.http
      .post(url, data, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token),
      })
      .pipe(
        map((res: any) => {
          if (res.code == 401) {
            let toast: any = {};
            if (
              sessionStorage.getItem(`${COOKIE_PREFIX}_token`) === null &&
              this.cookieService.get(`${COOKIE_PREFIX}_token`) !== undefined
            ) {
              // toast = {
              //     type: 'error',
              //     title: 'Found session of Admin, redirecting back to home page. Please use "Switch" feature if needed.'
              // }
              // this.toasterService.pop(toast);
              this.toasterService.error(
                'Found session of Admin, redirecting back to home page. Please use "Switch" feature if needed.'
              );

              $("div.modal-backdrop").remove();
              this.router.navigate(["/"]);

              return false;
            } else {
              sessionStorage.removeItem(`${COOKIE_PREFIX}_token`);
              localStorage.removeItem(`${COOKIE_PREFIX}_token`);
              this.cookieService.remove(`${COOKIE_PREFIX}_token`);
              this.cookieService.remove("current_user");
              // this.toasterService.pop({ type: 'error', title: res.message });
              this.toasterService.error(res.message);
              this.router.navigate(["/login"]);
              window.location.reload();

              return false;
            }
          }
          if (res.code == 402) {
            for (const index in res.data) {
              if (Object.prototype.hasOwnProperty.call(res.data, index)) {
                this.toasterService.error(res.data[index][0]);
              }
            }
            var data = [];
            return false;
          } else {
            if (flash_flag) {
              this.toasterService[res.type](res.message);
            }
            if (flash_flag == "error_log" && res.type == "error") {
              this.toasterService[res.type](res.message);
            }
            return res;
          }
        }),
        catchError((error: any) => {
          var html_body = "";
          if (error.status == 400) {
            if (error.error.errors != "") {
              var error_json = error.error.errors;
              var html_body = "<ul>";
              for (const index in error_json) {
                if (Object.prototype.hasOwnProperty.call(error_json, index)) {
                  if (error_json[index]) {
                    html_body += "<li>" + error_json[index] + "</li>";
                  }
                }
              }
              html_body += "</ul>";
            }
          }

          // var toast: any = {
          //     type: 'error',
          //     title: error.error.message,
          //     body: html_body,
          // bodyOutputType: BodyOutputType.TrustedHtml
          // };

          this.toasterService.error(error.error.message);
          return observableThrowError(error);
        })
      );
  }
  public get(url: string): Observable<any> {
    const token = localStorage.getItem(`${COOKIE_PREFIX}_token`);

    if (!token) {
      this.router.navigate(["/"]);
    }
    return this.http
      .get(url, {
        headers: new HttpHeaders().set("Authorization", "Bearer " + token),
        responseType: "blob",
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => new Error(error));
        })
      );
  }
}
