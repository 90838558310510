import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { COOKIE_PREFIX } from "src/environments/environment";
import { API_URLS } from "../../config/app.constants";
import { HttpinterceptorService } from "./httpinterceptor.service";

@Directive()
@Injectable({
  providedIn: "root",
})
export class CartsService {
  @Output() cartItemsCount = new EventEmitter();
  @Output() cartItems = new EventEmitter();
  cartDetails: any = [{ items: [] }];

  constructor(private http: HttpinterceptorService) {}

  public addToCart(data) {
    sessionStorage.setItem("cartDetails", data);
    this.cartItemsCount.emit(this.getCartCount());
    this.cartItems.emit(this.getCartItems());
  }

  public getCartItems() {
    return sessionStorage.getItem("cartDetails");
  }

  public getCartWithApi() {
    const CartId = sessionStorage.getItem("guest_id")
      ? sessionStorage.getItem("guest_id")
      : sessionStorage.getItem(`${COOKIE_PREFIX}_token`);
    const selectedOutpostId = localStorage.getItem("selectedOutpostId");
    if (CartId) {
      this.http
        .post(
          API_URLS.GET_CART,
          { guest_id: CartId, out_post_id: selectedOutpostId },
          false
        )
        .subscribe(
          (res) => {
            if (res.code == "200" && res.type === "success") {
              if (res.data) {
                this.cartDetails = res.data;
                this.getCartData();
                this.addToCart(JSON.stringify(this.cartDetails));
              }
            }
          },
          (error) => {
            return false;
          }
        );
    }
  }

  public removeItemApi(itemId) {
    this.http
      .post(API_URLS.REMOVE_ITEM_CART, { cart_id: itemId }, true)
      .subscribe(
        (res) => {
          if (res.code === 200 && res.type === "success") {
            this.getCartWithApi();
          }
        },
        (error) => {
          return false;
        }
      );
  }

  public emptyCart(logoutTFlag) {
    sessionStorage.removeItem("cartDetails");
    if (logoutTFlag) {
      sessionStorage.removeItem(`${COOKIE_PREFIX}_token`);
    }
    this.cartItemsCount.emit(this.getCartCount());
    this.cartItems.emit(this.getCartItems());
  }

  public getCartCount() {
    const products = JSON.parse(sessionStorage.getItem("cartDetails"));
    if (products) {
      return products.items.length;
    } else {
      return 0;
    }
  }
  getCartData() {
    if (this.cartDetails) {
      for (const index in this.cartDetails.items) {
        if (
          Object.prototype.hasOwnProperty.call(this.cartDetails.items, index)
        ) {
          if (this.cartDetails.items[index].pet_info) {
            if (
              this.cartDetails.items[index].pet_info &&
              typeof this.cartDetails.items[index].pet_info === "string"
            ) {
              this.cartDetails.items[index].pet_info = JSON.parse(
                this.cartDetails.items[index].pet_info
              );
            } else {
              this.cartDetails.items[index].pet_info =
                this.cartDetails.items[index].pet_info;
            }
            if (this.cartDetails.items[index].item_type == "subscription") {
              let cart_price =
                this.cartDetails.items[index].plan_level.monthly_price;
              for (const key in this.cartDetails.items[index].extra_services) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.cartDetails.items[index].extra_services,
                    key
                  )
                ) {
                  cart_price =
                    cart_price +
                    this.cartDetails.items[index].extra_services[key]
                      .monthly_price;
                }
              }
              this.cartDetails.items[index]["cart_price"] = cart_price;
            }
          }
        }
      }
    }
  }
}
