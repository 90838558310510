import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import {
  API_URLS,
  EMAIL_PATTERN,
  WHOLE_NUMBER_PATTERN,
} from "src/app/config/app.constants";
import { Globals } from "src/app/globals";
import { COOKIE_PREFIX } from "src/environments/environment";
import { AuthService } from "../../services/auth.service";
import { CartsService } from "../../services/carts.service";
import { HttpinterceptorService } from "../../services/httpinterceptor.service";
import { LoginService } from "../../services/login.service";
import { Utils } from "../../utils";

@Component({
  selector: "app-login-modal",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxTrimDirectiveModule,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: "./login-modal.component.html",
  styleUrl: "./login-modal.component.scss",
})
export class LoginModalComponent {
  @Input() fromPage;

  loginPageSubmitted: boolean = false;
  registrationPageSubmitted: boolean = false;
  forgetPageSubmitted: boolean = false;
  showHidePassword: boolean = false;
  showHideConfirmPassword: boolean = false;

  loginUser: FormGroup;
  addUser: FormGroup;
  userDetails: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    public cartsService: CartsService,
    public loginService: LoginService,
    public globals: Globals,
    private activeModal: NgbModal,
    private http: HttpinterceptorService
  ) {
    const token = localStorage.getItem(`${COOKIE_PREFIX}_token`);
    if (token) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {
    if (this.fromPage == "login") {
      this.initLoginForm();
    } else {
      this.initRegistrationForm();
    }
  }

  initLoginForm() {
    this.loginUser = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
    });
  }

  initRegistrationForm() {
    this.addUser = this.formBuilder.group(
      {
        first_name: ["", [Validators.required]],
        last_name: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
        hear_about_us: ["", {}],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(20),
          ],
        ],
        password_confirmation: ["", [Validators.required]],
        phone_no: [
          "",
          [
            Validators.required,
            Validators.pattern(WHOLE_NUMBER_PATTERN),
            Validators.minLength(6),
            Validators.maxLength(15),
          ],
        ],
        home_phone: ["", [Validators.pattern(WHOLE_NUMBER_PATTERN)]],
        country: [
          { value: "United States", disabled: true },
          [Validators.required],
        ],
        state: ["", [Validators.required]],
        city: ["", [Validators.required]],
        zipcode: [
          "",
          [
            Validators.required,
            Validators.pattern(WHOLE_NUMBER_PATTERN),
            Validators.minLength(5),
            Validators.maxLength(9),
          ],
        ],
        address: ["", [Validators.required]],
        tdl: ["", [Validators.pattern(WHOLE_NUMBER_PATTERN)]],
        term_condition: ["", [Validators.requiredTrue]],
      },
      {
        validator: Utils.MustMatch("password", "password_confirmation"),
      }
    );
  }

  /**
   * This method is the show or hide password
   * */
  passwordShowHide() {
    this.showHidePassword = !this.showHidePassword;
  }

  /**
   * This method is the show or hide confirm-password
   * */
  confirmPasswordShowHide() {
    this.showHideConfirmPassword = !this.showHideConfirmPassword;
  }

  initForgetPassForm() {
    this.userDetails = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    });
  }
  // Create use account

  saveUserData() {
    this.registrationPageSubmitted = true;
    if (this.addUser.valid) {
      this.addUser.value.guest_id = sessionStorage.getItem("guest_id");
      const nextPage = sessionStorage.getItem("nextPage");
      this.auth.register(this.addUser.value).subscribe(
        (res) => {
          this.registrationPageSubmitted = false;
          this.loginService.userData.push(this.globals.currentUser);
          if (res) {
            this.addUser.reset();
            if (nextPage === "checkout") {
              this.router.navigate(["checkout"]);
            } else {
              this.router.navigate(["/"]);
            }
          } else {
            return false;
          }
          this.activeModal.dismissAll();
        },
        (error) => {
          this.registrationPageSubmitted = false;
        }
      );
    } else {
      return false;
    }
  }

  // recover user password

  forgotUser() {
    this.forgetPageSubmitted = true;
    if (this.userDetails.valid) {
      this.http
        .post(API_URLS.FORGOT_USER, this.userDetails.value, true)
        .subscribe(
          (res) => {
            this.forgetPageSubmitted = false;
            if (res.code == 200 && res.type === "success") {
              this.userDetails.reset();
              this.router.navigate(["/"]);
            }
          },
          (error) => {
            this.forgetPageSubmitted = false;
          }
        );
    } else {
      return false;
    }
  }

  toggleLoginSignupPage(from) {
    if (from == "login") {
      this.registrationPageSubmitted = false;
      this.initRegistrationForm();
      this.fromPage = "signup";
    } else if (from == "signup") {
      this.loginPageSubmitted = false;
      this.initLoginForm();
      this.fromPage = "login";
    } else if (from == "forgetPssword") {
      this.activeModal.dismissAll();
      this.router.navigate(["/forgot_password"]);
    }
  }
  cancelForgotPassword() {
    this.activeModal.dismissAll();
    this.router.navigate(["/login"]);
  }
}
