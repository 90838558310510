import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

// operators
declare var $: any;

@Injectable()
export class HttpInterceptor {

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request);
    }

    constructor() { }

}