import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie";
import { ToastrService } from "ngx-toastr";
import { COOKIE_PREFIX, environment } from "src/environments/environment";
import { Globals } from "../../globals";
import { LoginModalComponent } from "../header/login-modal/login-modal.component";
import { AuthService } from "./auth.service";
import { CartsService } from "./carts.service";

declare var $: any;

@Directive()
@Injectable()
export class LoginService {
  @Output() isModalClosed = new EventEmitter();

  //define variable to redirect after login
  redirectPage = "";

  //by default it opens a login modal
  isLoginModal = true;

  submitted: boolean = false;
  isLogin: boolean = false;
  isRedirectPage: boolean = false;

  //variable is use to manage header seaction
  userData: any = [];
  jwtHelper = new JwtHelperService();

  isRegister: boolean = false;

  loginByContinue: any;
  modalRef: any;

  constructor(
    private globals: Globals,
    private router: Router,
    private toasterService: ToastrService,
    public cookieService: CookieService,
    public auth: AuthService,
    public cartsService: CartsService,
    private modalService: NgbModal
  ) {
    if (Object.keys(this.globals.currentUser).length) {
      this.userData.push(this.globals.currentUser);
    }
  }

  /**
   * @param isLoginModal flag defines which form will open
   * @param url url on where user redirect after login
   */
  openLoginModal(isLoginModal, url?, fromPage?) {
    this.submitted = false;
    this.isLoginModal = isLoginModal;
    this.loginByContinue = fromPage;
    if (this.router.url === "/health-map") {
      url = "/health-map";
    }
    /**
     * prevent modal to open if user is already loged in
     */
    if (
      this.globals?.currentUser &&
      Object.keys(this.globals?.currentUser).length
    ) {
      this.redirectPage = "";
      this.toasterService.error("Your are already Logged In");
    } else {
      if (url) {
        this.redirectPage = url;
        this.isRedirectPage = true;
      }
      this.openLoginPage(this.isLoginModal);
    }
  }

  openLoginPage(fromPage) {
    this.modalRef = this.modalService.open(LoginModalComponent, {
      size: "xl",
      centered: true,
      backdrop: true,
      keyboard: false,
      windowClass: "login-modal",
    });
    this.modalRef.componentInstance.fromPage = fromPage;
  }

  /**
   * function to get redirect url after login
   */
  getRedirectUrl() {
    return this.redirectPage == "" ? "/" : this.redirectPage;
  }

  /**
   * function to perform login functionality after click on submit
   */
  login(loginUser) {
    this.submitted = true;
    if (loginUser.valid) {
      this.isLogin = true;
      loginUser.value.guest_id = sessionStorage.getItem("guest_id");

      this.auth.login(loginUser.value).subscribe(
        (res) => {
          this.submitted = false;
          this.isLogin = false;
          this.modalRef.close();
          if (!this.isRedirectPage && this.loginByContinue) {
            this.isModalClosed.emit(true);
          } else {
            this.router.navigate(["/"]);
          }
          this.userData.push(this.globals.currentUser);
          if (res) {
            loginUser.reset();
            if (this.isRedirectPage) {
              this.isRedirectPage = false;
              this.router.navigate([this.getRedirectUrl()]);
            }
          } else {
            return false;
          }
          if (environment.production) {
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(
              localStorage.getItem(`${COOKIE_PREFIX}_token`)
            );
            window.leadJobId = decodedToken?.email;
          }
        },
        (error) => {
          this.submitted = false;
          this.isLogin = false;
        }
      );
    } else {
      return false;
    }
  }

  /**
   * function to perform login functionality after click on submit
   */
  saveUserData(addUser) {
    this.submitted = true;
    if (!addUser.value.country) {
      addUser.value.country = "united states";
    }
    if (addUser.valid) {
      this.isRegister = true;
      addUser.value.guest_id = sessionStorage.getItem("guest_id");
      addUser.value.out_post_id = localStorage.getItem("selectedOutpostId");
      this.auth.register(addUser.value).subscribe(
        (res) => {
          this.submitted = false;
          this.isRegister = false;
          this.modalRef.close();
          if (!this.isRedirectPage) {
            this.isModalClosed.emit(true);
          }
          this.userData.push(this.globals.currentUser);
          if (res) {
            addUser.reset();
            if (this.isRedirectPage) {
              this.isRedirectPage = false;
              this.router.navigate([this.getRedirectUrl()]);
            }
          } else {
            return false;
          }
        },
        (error) => {
          this.submitted = false;
          this.isRegister = false;
        }
      );
    } else {
      addUser.markAllAsTouched();
      return false;
    }
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
  changeUserData(token) {
    this.userData = [];
    this.userData.push(this.jwtHelper.decodeToken(token));
  }
}
