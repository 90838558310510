export class User {
  _id?: string;
  id?: string;
  full_name?: String;
  username?: string;
  email?: string;
  auth_token?: string;
  access?: any;
  role?: string;
  switch_back?: string;
  switch?: boolean;
  type?: string;
}
