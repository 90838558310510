import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { API_URLS } from '../../../config/app.constants';
import { HttpinterceptorService } from '../../../shared/services/httpinterceptor.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class PrintComponent implements AfterViewInit {
  thumbnail;
  isPetDocument: boolean = false;

  constructor(
    private http: HttpinterceptorService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer

  ) { }

  ngAfterViewInit() {
    if (this.route['_futureSnapshot'].queryParams.module == 'healthmap_report') {
      this.gethealthmapReport();
      return false;
    }

    if (this.route['_futureSnapshot'].queryParams.module == 'pet_documents') {
      this.isPetDocument = true;
      this.getPetDocumentInformation();
    } else {
      this.getMedicationInformation();
    }

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem('healthmapParams');
  }

  getMedicationInformation() {
    this.route.params.subscribe((params) => {
      const template_id = params['id'];
      this.http.post(API_URLS.PRINT_MEDICATION_DETAILS + template_id, {}, false).subscribe(
        resp => {
          const hostName = window.location.protocol + '//' + window.location.hostname;
          setTimeout(function () {
            this.element = document.getElementById('myFrame');
            this.element.src = hostName + '/' + resp.data.pdf;
          }, 2000);
        });
    });
  }
  getPetDocumentInformation() {
    this.route.params.subscribe((params) => {
      const petId = params['petId'];
      const documentId = params['id'];
      this.http.get(API_URLS.HEALTH_MAP_DOCUMENT + petId + '/' + documentId).subscribe(
        response => {
          if (response.type === "application/pdf") {
            this.createPdfFromBlob(response);
          } else {
            this.createImageFromBlob(response);
          }
        }
      );
    });
  }
  createImageFromBlob(image: Blob) {
    const promise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(image);
    });
    promise.then((img: any) => {
      this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(img);
    });
  }

  createPdfFromBlob(image: Blob) {
    var file = new Blob([image], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_self");
  }
  gethealthmapReport() {
    const apiParams = JSON.parse(localStorage.getItem('healthmapParams'));
    this.route.params.subscribe((params) => {
      const petId = params['id'];

      if (!petId) {
        this.http.post(API_URLS.PRINT_HEALTH_MAP, apiParams, false).subscribe(
          response => {
            const hostName = window.location.protocol + '//' + window.location.hostname;
            setTimeout(function () {
              this.element = document.getElementById('myFrame');
              this.element.src = hostName + '/' + response.data.pdf;
            }, 2000);
          }
        );
      } else {
        this.http.post(API_URLS.PRINT_HEALTH_MAP + '/' + petId, apiParams, false).subscribe(
          response => {
            const hostName = window.location.protocol + '//' + window.location.hostname;
            setTimeout(function () {
              this.element = document.getElementById('myFrame');
              this.element.src = hostName + '/' + response.data.pdf;
            }, 2000);
          }
        );
      }
    });
  }
}
