import { Injectable } from '@angular/core';

import { API_URLS } from '../../config/app.constants';
import { HttpinterceptorService } from './httpinterceptor.service';

@Injectable()
export class UserService {

  constructor(
    private http: HttpinterceptorService
  ) { }

  public login(credentials) {
    return this.http.post(API_URLS.USER_LOGIN, credentials, true);
  }

  public register(userData) {
    return this.http.post(API_URLS.ADD_USER, userData, true);
  }
}
