import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { Globals } from '../../../globals';
import { AuthService } from '../auth.service';
@Injectable()
export class AuthGuardFrontUsers  {

  constructor(
    private toasterService: ToastrService,
    public auth: AuthService,
    private globals: Globals
  ) { }

  canActivate() {
    const role = this.globals.currentUser.type;
    let can_access = true;
    if (role && role != 'backend') {
      can_access = true;
    } else {
      can_access = false;
      this.toasterService.error('You Are Not Authorized');
    }
    return can_access;
  }

}
