import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";

// operators
import { COOKIE_PREFIX } from "src/environments/environment";
import { AuthService } from "../auth.service";

@Injectable()
export class AuthGuardLogin {
  constructor(
    public auth: AuthService,
    private router: Router,
    public cookieService: CookieService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.cookieService.get(`${COOKIE_PREFIX}_token`);
    if (token) {
      return new Promise((resolve) => {
        if (token !== null) {
          const decodedUser = this.auth.decodeUserFromToken(token);
          this.auth.setCurrentUser(decodedUser);
          resolve(true);
        }
      });
    } else {
      if (!this.auth.loggedIn) {
        this.router.navigate(["/login"]);
      }
      return this.auth.loggedIn;
    }
  }
}
